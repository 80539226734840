import React, { useState } from "react"
import { Field, Formik } from "formik"
import PropTypes from "prop-types"
import "./forgottenPassword.css"
import AelAuthentificationService from "../../services/aelAuthentificationService"
import ForgotterPasswordSchema from "../../validators/forgottenPassword/forgottenPasswordSchema"
import classNames from "classnames"

const ForgottenPassword = ({}) => {
  const [error, setError] = useState(false)
  const [success, setSucces] = useState(false)

  const getBaseUrl = () => {
    if (typeof window !== "undefined" && window) {
      if (!window.location.origin) {
        window.location.origin =
          window.location.protocol +
          "//" +
          window.location.hostname +
          (window.location.port ? ":" + window.location.port : "")
      }
      return window.location.origin
    }
  }
  const handleSubmit = (values, setSubmitting, setValues) => {
    const { email } = values
    AelAuthentificationService.initResetPassword(email, getBaseUrl()).then(
      () => {
        setSucces(true)
        setError(false)
      },
      () => {
        setError(true)
        setSucces(false)
      }
    )
  }

  const canSubmit = errors => {
    return !errors || !errors.email
  }

  const values = {
    email: "",
  }

  return (
    <Formik
      onSubmit={(values, { setValues, setSubmitting }) => {
        handleSubmit(values, setSubmitting, setValues)
      }}
      initialValues={values}
      validationSchema={ForgotterPasswordSchema}
      render={props => (
        <div className={"row py-5 mgc-forgotten-password"}>
          <div className="col-12">
            <div
              className="alert alert-success text-left"
              role="alert"
              hidden={!(success && !error)}
            >
              Votre demande a été prise en compte. <br />
              Vous allez recevoir un E-mail contenant un lien pour changer votre
              mot de passe
            </div>
            <div
              className="alert alert-danger text-left"
              role="alert"
              hidden={!(error && !success)}
            >
              Une erreur est survenue
            </div>
          </div>
          <div className="col-12 text-center">
            <label htmlFor={"email"}>
              Veuillez saisir votre adresse E-mail pour demander un nouveau mot
              de passe:
            </label>
          </div>
          <div className="col-12">
            <form
              onSubmit={props.handleSubmit}
              className="forgotten-password form-inline justify-content-center"
            >
              <div className="form-row">
                <Field
                  type="email"
                  className={`form-control ${
                    props.errors && props.errors.email ? "is-invalid" : ""
                  }`}
                  placeholder="E-mail"
                  name={"email"}
                />
                <button
                  type="submit"
                  className={classNames(
                    "btn btn-primary mx-0 mx-md-2 mt-2 mt-md-0",
                    {
                      disabled:
                        !props.dirty || (props.errors && props.errors.email),
                    }
                  )}
                  disabled={
                    !props.dirty || (props.errors && props.errors.email)
                  }
                >
                  Envoyer
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    />
  )
}

ForgottenPassword.propTypes = {}

export default ForgottenPassword
