import React from "react"
import Layout from "../components/layout"
import ForgottenPassword from "../components/global/forgottenPassword"


const MotDePasseOublie = props => {
  const metaRobots = [
    {
      name: "robots",
      content: "noindex",
    },
  ]
  return (
    <Layout
      titleSeo="Mot de passe oublié"
      description={
        "Vous avez oublié ou perdu votre mot de passe permettant de finaliser votre adhésion en ligne ? Saisissez votre adresse email afin de le réinitialiser."
      }
      keywordsSeo={[`password`]}
      meta={metaRobots}
    >
      <section className="pageHeader">
        <div className="pageHeaderContainer">
          <nav>
            <ul className="breadcrumb">
              <li className="breadcrumbItem">
                <a
                  href="/"
                  className="breadcrumbItemLink breadcrumbItemLinkHome"
                >
                  <i className="icon-mgc" />
                </a>
              </li>
              <li className="breadcrumbItem" key="/mot-de-passe-oublie">
                <a
                  className="breadcrumbItemLink breadcrumbItemLinkMiddle"
                  href="/mot-de-passe-oublie"
                >
                  Mot de passe oublié
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </section>
      <section className="pageHeader callback">
        <div>
          <h1 className="pageHeaderTitle">Mot de passe oublié</h1>
        </div>
      </section>
      <section className="container text-center">
        <ForgottenPassword />
      </section>
    </Layout>
  )
}

export default MotDePasseOublie
