import Axios from "axios"

export default class AelAuthentificationService {
static BASE_URL = `${process.env.GATSBY_CMS_API_URL}/api-user-site`
  static register(email, firstName, lastName, password, id) {
    const url = `${this.BASE_URL}/register`
/*    const url = `http://localhost:8080/api/register`*/
    return id ? Promise.resolve() : Axios.post(url, { email, firstName, lastName, password })
  }
  static authenticate(email, password) {
    const url = `${this.BASE_URL}/authenticate`
/*    const url = `http://localhost:8080/api/authenticate`*/
    return Axios.post(url, { email, password })
  }
  static initResetPassword(email, baseUrl) {
    const url = `${this.BASE_URL}/reset-password/init`
    return Axios.post(url, {email, baseUrl})
  }
  static finishResetPassword(key, newPassword) {
    const url = `${this.BASE_URL}/reset-password/finish`
    return Axios.post(url, { key, newPassword })
  }
}
